import Layout from '@/View/Admin/Layout/layout.vue'
import colors from '@/assets/colors.json'
export const Routing = { // dashboard object start
    path: '/',
    component: Layout,
    hidden: false,
    meta: {
        title: 'dashboards',
        icon: 'fas fa-border-all',
    },
    children: [
        {
            path: '/',
            component: () =>
                import ('@/View/Admin/dashboard/dashboard.vue'),
            name: 'Dashboard',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Dashboard',
                loadPage: 'Dashboard',
                permissionList: ['abc', 'def'],
                routeIcon: 'dashboardSvg',
                selected: false,
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            }
        },
        {
            path: '/organization',
            component: () =>
                import ('@/View/Admin/organization/listing.vue'),
            name: 'Organizations',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Organizations',
                loadPage: 'Organizations',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'orgSideIcon',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/user',
            component: () =>
                import ('@/View/Admin/users/listing.vue'),
            name: 'Users',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Users',
                loadPage: 'Users',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'userSidebar',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/appUser',
            component: () =>
                import ('@/View/Admin/device/listing.vue'),
            name: 'Device',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Device',
                loadPage: 'Device',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'deviceSidebar',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/customer',
            component: () =>
                import ('@/View/Admin/customer/listing.vue'),
            name: 'Customers',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Customers',
                loadPage: 'Customers',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'customerSide',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/lead',
            component: () =>
                import ('@/View/Admin/lead/listing.vue'),
            name: 'Leads',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Leads',
                loadPage: 'Leads',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'leadSide',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/support',
            component: () =>
                import ('@/View/Admin/support/listing.vue'),
            name: 'SupportList',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Support',
                loadPage: 'SupportList',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'SupportSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/feedback',
            component: () =>
                import ('@/View/Admin/feedback/listing.vue'),
            name: 'FeedBack',
            meta: {
                icon: 'fas fa-border-all',
                title: 'FeedBack',
                loadPage: 'FeedBack',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'feedbackSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/activity',
            component: () =>
                import ('@/View/Admin/activityLog/listing.vue'),
            name: 'ActivityLog',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Activity Log',
                loadPage: 'ActivityLog',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'ActivityIcon',
                routIconSize: { height: '40px', width: '40px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/setting',
            component: () =>
                import ('@/View/Admin/setting/dashboard.vue'),
            name: 'Setting',
            meta: {
                icon: 'fas fa-border-all',
                title: 'Setting',
                loadPage: 'Setting',
                selected: false,
                permissionList: ['abc', 'def'],
                routeIcon: 'SettingSvg',
                routIconSize: { height: '35px', width: '35px', color: colors.text2 }
            },
            children: [
            ]
        },
        {
            path: '/emailtemplate/:entityId/:slug',
            component: () =>
                import ('@/View/Admin/setting/email/emailTemplate.vue'),
            name: 'EmailTemplateList',
            hidden: true,
            meta: {
                title: 'EmailTemplateList',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/emailtemplate/edit/:slug/:emailTemplateId',
            component: () =>
                import ('@/View/Admin/setting/email/editTemplate.vue'),
            name: 'EditEmailTemplate',
            hidden: true,
            meta: {
                title: 'EditEmailTemplate',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/organization/:orgId',
            component: () =>
                import ('@/View/Admin/organization/detail.vue'),
            name: 'OrganizationDetail',
            hidden: true,
            meta: {
                title: 'OrganizationDetail',
                loadPage: 'Organizations',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/attachments',
            component: () =>
                import ('@/View/Admin/attachmentList.vue'),
            name: 'attachmentList',
            hidden: true,
            meta: {
                title: 'Attachments',
                loadPage: 'Organizations',
                permissionList: ['abc', 'def'],
            }
        },
        {
          path: '/leads/detail/:customerId',
          name: 'LeadsDetail',
          component: () => import('@/View/Admin/lead/detail.vue'),
          hidden: true,
          meta: {
            title: 'Leads Detail',
            selected: false,
            permissionList: [],
            loadPage: 'Leads',
          }
        },
        {
            path: '/customer/detail/:customerId',
            name: 'CustomersDetail',
            component: () => import('@/View/Admin/customer/detail.vue'),
            hidden: true,
            meta: {
              title: 'Customers Detail',
              selected: false,
              permissionList: [],
              loadPage: 'Customers',
            }
          },
        {
            path: '/:orgId/job/visit/detail/:jobId/:visitId',
            name: 'visitDetail',
            component: () => import('@/View/Admin/customer/jobs/detail.vue'),
            hidden: true,
            meta: {
              title: 'Visits Detail',
              selected: false,
              permissionList: [],
              loadPage: 'Customers',
            }
          },
        {
            path: '/:orgId/quote/:quoteId',
            name: 'QuoteDetail',
            component: () => import('@/View/Admin/customer/components/quoteDetail.vue'),
            hidden: true,
            meta: {
              title: 'Quote Detail',
              selected: false,
              permissionList: [],
              loadPage: 'Organizations',
            }
          },
        {
            path: 'task/:orgId/:taskId',
            name: 'TaskDetail',
            component: () => import('@/View/Admin/customer/components/taskdetail.vue'),
            hidden: true,
            meta: {
              title: 'Task Detail',
              selected: false,
              permissionList: [],
              loadPage: 'Organizations',
            }
          },
        {
            path: 'request/:orgId/:requestId',
            name: 'RequestDetail',
            component: () => import('@/View/Admin/customer/components/requestdetail.vue'),
            hidden: true,
            meta: {
              title: 'Request Detail',
              selected: false,
              permissionList: [],
              loadPage: 'Organizations',
            }
          },
        {
            path: '/payment/detail/:paymentId',
            name: 'PaymentDetail',
            component: () => import('@/View/Admin/customer/paymentDetail.vue'),
            hidden: true,
            meta: {
              title: 'Payment Detail',
              selected: false,
              permissionList: [],
              loadPage: 'Organizations',
            }
          },
        {
            path: '/invoice/detail/:invoiceId',
            name: 'InvoiceDetail',
            component: () => import('@/View/Admin/customer/invoiceDetail.vue'),
            hidden: true,
            meta: {
              title: 'Invoice Detail',
              selected: false,
              permissionList: [],
              loadPage: 'Organizations',
            }
          },
        {
            path: '/job/detail/:orgId/:jobId',
            name: 'CustomerJobDetail',
            component: () => import('@/View/Admin/customer/jobs/jobDetail.vue'),
            hidden: true,
            meta: {
              title: 'Job Detail',
              selected: false,
              permissionList: [],
              loadPage: 'Customers',
            }
          },
        {
            path: '/support/:supid',
            component: () =>
                import ('@/View/Admin/support/detail.vue'),
            name: 'SupportDetail',
            hidden: true,
            meta: {
                title: 'SupportDetail',
                loadPage: 'SupportList',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/feedback/:feedbackId',
            component: () =>
                import ('@/View/Admin/feedback/detail.vue'),
            name: 'FeedbackDetail',
            hidden: true,
            meta: {
                title: 'FeedbackDetail',
                loadPage: 'FeedBack',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/appSetting',
            component: () =>
                import ('@/View/Admin/setting/appSetting/list.vue'),
            name: 'AppSetting',
            hidden: true,
            meta: {
                title: 'AppSetting',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/plans',
            component: () =>
                import ('@/View/Admin/setting/plan/listing.vue'),
            name: 'Plans',
            hidden: true,
            meta: {
                title: 'Plans',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/plan/:planId',
            component: () =>
                import ('@/View/Admin/setting/plan/detail.vue'),
            name: 'PlanDetail',
            hidden: true,
            meta: {
                title: 'PlanDetail',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/plan/add/:planId',
            component: () =>
                import ('@/View/Admin/setting/plan/addNewPlan.vue'),
            name: 'addNewPlan',
            hidden: true,
            meta: {
                title: 'addNewPlan',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/user/profile',
            name: 'UserDetail',
            component: () => import('@/View/Admin/setting/users/userDetail.vue'),
            hidden: true,
            meta: {
              title: 'User Detail',
              permissionList: ['abc', 'def'],
              loadPage: 'Setting',
            }
          },
        {
            path: '/plan/edit/:planId',
            component: () =>
                import ('@/View/Admin/setting/plan/addNewPlan.vue'),
            name: 'EditNewPlan',
            hidden: true,
            meta: {
                title: 'editNewPlan',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/emailConfiguration',
            component: () =>
                import ('@/View/Admin/setting/emailConf/listing.vue'),
            name: 'EmailConfiguration',
            hidden: true,
            meta: {
                title: 'EmailConfiguration',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
        {
            path: '/passwordManager',
            component: () =>
                import ('@/View/Admin/setting/password/detail.vue'),
            name: 'PasswordManager',
            hidden: true,
            meta: {
                title: 'Password Manager',
                loadPage: 'Setting',
                permissionList: ['abc', 'def'],
            }
        },
    ]
}