<template>
 <!-- <svg :width="width" :height="height" fill="none"  viewBox="0 -0.5 25 25"  xmlns="http://www.w3.org/2000/svg">
<path d="M19.5 19H9.5C7.29086 19 5.5 17.2091 5.5 15V5" :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.619 7.25C15.2048 7.25 14.869 7.58579 14.869 8C14.869 8.41421 15.2048 8.75 15.619 8.75V7.25ZM19.5 8.75C19.9142 8.75 20.25 8.41421 20.25 8C20.25 7.58579 19.9142 7.25 19.5 7.25V8.75ZM20.25 8C20.25 7.58579 19.9142 7.25 19.5 7.25C19.0858 7.25 18.75 7.58579 18.75 8H20.25ZM18.75 11.607C18.75 12.0212 19.0858 12.357 19.5 12.357C19.9142 12.357 20.25 12.0212 20.25 11.607H18.75ZM20.0765 8.47973C20.3414 8.16133 20.2981 7.68844 19.9797 7.42349C19.6613 7.15855 19.1884 7.20188 18.9235 7.52027L20.0765 8.47973ZM15.5 12.807L16.0038 13.3626C16.0298 13.339 16.054 13.3137 16.0765 13.2867L15.5 12.807ZM13.931 12.868L13.4663 13.4567L13.4718 13.461L13.931 12.868ZM12.882 12.04L13.3467 11.4513L13.3429 11.4483L12.882 12.04ZM11.2 12.223L10.6226 11.7443C10.6152 11.7532 10.608 11.7623 10.601 11.7716L11.2 12.223ZM11.072 12.375L11.6074 12.9002C11.6112 12.8963 11.615 12.8923 11.6187 12.8884L11.072 12.375ZM7.83052 14.6088C7.54048 14.9046 7.54508 15.3794 7.8408 15.6694C8.13652 15.9595 8.61138 15.9549 8.90141 15.6592L7.83052 14.6088ZM15.619 8.75H19.5V7.25H15.619V8.75ZM18.75 8V11.607H20.25V8H18.75ZM18.9235 7.52027L14.9235 12.3273L16.0765 13.2867L20.0765 8.47973L18.9235 7.52027ZM14.9962 12.2514C14.8266 12.4052 14.5711 12.4151 14.3901 12.275L13.4718 13.461C14.228 14.0465 15.2953 14.005 16.0038 13.3626L14.9962 12.2514ZM14.3956 12.2793L13.3466 11.4513L12.4173 12.6287L13.4663 13.4567L14.3956 12.2793ZM13.3429 11.4483C12.5051 10.7957 11.3004 10.9268 10.6226 11.7443L11.7773 12.7017C11.9377 12.5082 12.2228 12.4772 12.4211 12.6317L13.3429 11.4483ZM10.601 11.7716C10.5774 11.8029 10.5521 11.833 10.5252 11.8616L11.6187 12.8884C11.6826 12.8203 11.7427 12.7489 11.7989 12.6744L10.601 11.7716ZM10.5365 11.8498L7.83052 14.6088L8.90141 15.6592L11.6074 12.9002L10.5365 11.8498Z" :fill="color"/>
</svg> -->
<!-- <svg :width="width" :height="height" :fill="color" viewBox="0 0 26 26"  xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.29289 1.29289C9.48043 1.10536 9.73478 1 10 1H18C19.6569 1 21 2.34315 21 4V7C21 7.55228 20.5523 8 20 8C19.4477 8 19 7.55228 19 7V4C19 3.44772 18.5523 3 18 3H11V8C11 8.55228 10.5523 9 10 9H5V20C5 20.5523 5.44772 21 6 21H8C8.55228 21 9 21.4477 9 22C9 22.5523 8.55228 23 8 23H6C4.34315 23 3 21.6569 3 20V8C3 7.73478 3.10536 7.48043 3.29289 7.29289L9.29289 1.29289ZM6.41421 7H9V4.41421L6.41421 7ZM17 12C14.2386 12 12 14.2386 12 17C12 19.7614 14.2386 22 17 22C19.7614 22 22 19.7614 22 17C22 14.2386 19.7614 12 17 12ZM10 17C10 13.134 13.134 10 17 10C20.866 10 24 13.134 24 17C24 20.866 20.866 24 17 24C13.134 24 10 20.866 10 17ZM18 14C18 13.4477 17.5523 13 17 13C16.4477 13 16 13.4477 16 14V17C16 17.2164 16.0702 17.4269 16.2 17.6L17.7 19.6C18.0314 20.0418 18.6582 20.1314 19.1 19.8C19.5418 19.4686 19.6314 18.8418 19.3 18.4L18 16.6667V14Z" :fill="color"/>
</svg> -->
<!-- <svg :width="width" :height="height" :fill="color" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 64c0-17.7-14.3-32-32-32S0 46.3 0 64V400c0 44.2 35.8 80 80 80H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H80c-8.8 0-16-7.2-16-16V64zm406.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L320 210.7l-57.4-57.4c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L240 221.3l57.4 57.4c12.5 12.5 32.8 12.5 45.3 0l128-128z"/></svg> -->

<svg :width="width" :height="height" :fill="color" viewBox="0 0 512 512" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <title>history-list</title>
  <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g :fill="color" id="icon" transform="translate(33.830111, 42.666667)">
          <path d="M456.836556,405.333333 L456.836556,448 L350.169889,448 L350.169889,405.333333 L456.836556,405.333333 Z M328.836556,405.333333 L328.836556,448 L286.169889,448 L286.169889,405.333333 L328.836556,405.333333 Z M456.836556,341.333333 L456.836556,384 L350.169889,384 L350.169889,341.333333 L456.836556,341.333333 Z M328.836556,341.333333 L328.836556,384 L286.169889,384 L286.169889,341.333333 L328.836556,341.333333 Z M131.660221,261.176335 C154.823665,284.339779 186.823665,298.666667 222.169889,298.666667 C237.130689,298.666667 251.492003,296.099965 264.837506,291.382887 L264.838264,335.956148 C251.200633,339.466383 236.903286,341.333333 222.169889,341.333333 C175.041086,341.333333 132.37401,322.230407 101.489339,291.345231 L131.660221,261.176335 Z M456.836556,277.333333 L456.836556,320 L350.169889,320 L350.169889,277.333333 L456.836556,277.333333 Z M328.836556,277.333333 L328.836556,320 L286.169889,320 L286.169889,277.333333 L328.836556,277.333333 Z M222.169889,7.10542736e-15 C316.426487,7.10542736e-15 392.836556,76.4100694 392.836556,170.666667 C392.836556,201.752854 384.525389,230.897864 370.003903,256.000851 L317.574603,256.00279 C337.844458,233.356846 350.169889,203.451136 350.169889,170.666667 C350.169889,99.9742187 292.862337,42.6666667 222.169889,42.6666667 C151.477441,42.6666667 94.1698893,99.9742187 94.1698893,170.666667 C94.1698893,174.692297 94.3557269,178.674522 94.7192911,182.605232 L115.503223,161.830111 L145.673112,192 L72.836556,264.836556 L4.97379915e-14,192 L30.1698893,161.830111 L51.989071,183.641815 C51.6671112,179.358922 51.5032227,175.031933 51.5032227,170.666667 C51.5032227,76.4100694 127.913292,7.10542736e-15 222.169889,7.10542736e-15 Z M243.503223,64 L243.503223,159.146667 L297.903223,195.626667 L274.436556,231.04 L200.836556,182.186667 L200.836556,64 L243.503223,64 Z" id="Combined-Shape">
</path>
      </g>
  </g>
</svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
