<template>
  <div>
    <div id="myTopnav" class="topNav cust_card m-2 mt-0 bg-white hover:shadow-md rounded-md">
      <div class="menu_iconss bg-white justify-between">
        <div class="dashboard_menus d_menu_icon flex items-center">
          <img v-if="mobileView"
              @click.stop="smallViewOpenDrawer()"
              height="30px"
              class="mr-2"
              width="30px"
              src="@/assets/images/svg/menu-icon.svg"
              alt=""
            />
            <div class="text-center flex items-center justify-center cursor-pointer" @click="$router.push({ name: 'Dashboard' })">
              <img src="@/assets/images/Zizbey-logo-H-35.svg" />
              <div class="heading-1 font-semibold pl-1" style="color: #1295BA">ZizbeyJobs</div>
            </div>
          <!-- <span @click="$router.push({ name: 'Dashboard' })"><img src="@/assets/images/Zizbey-logo-H-35.svg" class=" h-8 cursor-pointer" alt="img"></span> -->
        </div>
        <div class="flex items-center">
          <div class="pr-3">
            <div class="p-2 items-center grid text-center">
              <p class="heading-4 text-text1 font-semibold">{{ EmpObj.firstName }} {{ EmpObj.lastName }}</p>
              <p class="heading-5 text-text2">({{ EmpObj.email }})</p>
            </div>
          </div>
          <div class="text-center right-2 relative">
            <div class="cursor-pointer" @click.stop="isOpenValue()">
              <div class="rounded-full border border-gray-50 h-12 w-12" dark>
                <img class="rounded-full h-12 w-12" src="@/assets/images/svg/user-color.svg">
              </div>
            </div>
            <div v-if="isOpen" class="top-12 right-0 fixed" >
            <div class="autoPadding px-0 pt-0" v-click-outside="onClickOutside">
              <div class="cust_card bg-white w-52 border border-gray-200">
                <div class="dividerLight"></div>
                <div class="heading-4 p-3 text-text2 font-semibold  cursor-pointer flex items-center  hover:bg-blue-100" @click="$router.push({ name: 'UserDetail' });isOpen = false">
                  <i class="fas fa-user h-4 w-4 text-primary"></i>
                  <p class="pl-4">Profile</p>
                </div>
                <div class="dividerLight"></div>
                <div class="heading-4 p-3 text-text2 font-semibold  cursor-pointer flex items-center hover:bg-blue-100" @click="$router.push({ name: 'Dashboard' });isOpen = false">
                  <i class="fas fa-home h-4 w-4 text-primary"></i>
                  <p class="pl-4">Dashboard</p>
                </div>
                <div class="dividerLight"></div>
                <div class="heading-4 p-3 text-text2 font-semibold hover:bg-blue-100 cursor-pointer flex items-center" @click="LogOut()">
                  <i class="fas fa-sign-out-alt h-4 w-4 text-primary"></i>
                  <p class="pl-4">Logout</p>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import {removeCookies} from '@/utils/cookies'
export default {
  directives: {
      clickOutside: vClickOutside.directive
    },
  components: {
  },
  name: "leftNavbar",
  props: ["routesList"],
  data() {
    return {
      isOpen: false,
      EmpObj: [],
      isfavorite: false,
      setFavIcon: false,
      menu: false,
      drawerIsOpen: false,
      showGlobalSearch: false,
      showDashboadMenus: false,
      showAddNewWorkMenus: false,
      showHistory: false,
      showAddFavorite: false,
      showAvtarMenu: false,
      mobileView: false,
      extraSmall: false,
      favoriteList: []
    };
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted() {
    let Isadmin =  JSON.parse(localStorage.getItem('employeeData1'))
    this.EmpObj = Isadmin
    this.resizeWindowHandler();
  },
  watch: {},
  methods: {
    smallViewOpenDrawer() {
      if (this.mobileView) {
        this.$root.$emit('openDialog', true)
      }
    },
    isOpenValue () {
      console.log('open value')
      this.isOpen = !this.isOpen
    },
    onClickOutside () {
      console.log('Close value', this.isOpen)
      this.isOpen = false
    },
    LogOut () {
      removeCookies('saToken')
      localStorage.clear()
      this.$store.dispatch('GetEmployeePermission', true)
      this.$router.push({name: 'login'})
      location.reload()
    },
    resizeWindowHandler() {
      if (window.innerWidth < 1024) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
      if (window.innerWidth < 600) {
        this.extraSmall = false;
      } else {
        this.extraSmall = false;
      }
    },
    beforeDestroy() {
  },
  },
};
</script>
<style scoped>
.menu_iconss {
  width: inherit;
  padding: 0px 0px 0px 0px;
  display: inherit;
  align-items: center;
}
.row_user_svg {
  height: 25px;
  width: 25px;
}
</style>