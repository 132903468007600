import Vue from 'vue'
import store from '@/store'

var moment = require('moment')
Vue.filter('phone', function (phone) {
  if (phone === undefined) {
    return ''
  }
  return phone.replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
})
Vue.filter('dayDateType', function (value) {
  if (value) {
    return moment(String(new Date(value))).calendar(null, {
      sameDay: 'ddd, MMM DD YYYY',
      lastDay: 'ddd, MMM DD YYYY',
      nextDay: 'ddd, MMM DD YYYY',
      lastWeek: 'ddd, MMM DD YYYY',
      sameElse: 'ddd, MMM DD YYYY'
    })
    //  moment().calendar()
  }
})
Vue.filter('filterForLocationTime', function (value) {
  if (value) {
    return moment(String(new Date(value))).calendar(null, {
      sameDay: '[Today] hh:mm A',
      lastDay: '[Yest] hh:mm A',
      nextWeek: 'DD MMM, hh:mm A',
      lastWeek: 'DD MMM, hh:mm A',
      sameElse: 'DD MMM, hh:mm A'
    })
    //  moment().calendar()
  }
})
Vue.filter('logTimeShow', function (value) {
  if (value) {
    return moment(String(new Date(value))).calendar(null, {
      sameDay: '[Today] hh:mm a',
      lastDay: '[Yest] hh:mm a',
      nextWeek: 'ddd, DD MMM, hh:mm a',
      lastWeek: 'ddd, DD MMM, hh:mm a',
      sameElse: 'ddd, DD MMM, hh:mm a'
    })
    //  moment().calendar()
  }
})
Vue.filter('dateManyAgo', function (stringDate) {
  let takvalue = new Date(stringDate)
  var currDate = new Date()
  var diffMs = currDate.getTime() - new Date(stringDate).getTime()
  var sec = diffMs / 1000
  if (sec >= 0 && sec <= 120) {
    return 'Just Now'
  }
  if (sec >= 30 && sec <= 60) {
    return parseInt(sec) + ' second' + (parseInt(sec) > 1 ? 's' : '') + ' ago'
  }
  var min = sec / 60
  if (min < 60) {
    return parseInt(min) + ' minute' + (parseInt(min) > 1 ? 's' : '') + ' ago'
  }
  var h = min / 60
  if (h < 24) {
    let val = moment(takvalue, 'hh:mm').format('hh:mm A')
    return 'Today' + " " +  val
  }
  if (h > 24) {
    return moment(takvalue, 'ddd DD-MMM-YYYY, hh:mm a').format('ddd DD-MMM-YY, hh:mm a')
  }
})
Vue.filter('dateManyDayAgo', function (stringDate) {
  let takvalue = new Date(stringDate)
  var currDate = new Date()
  var diffMs = currDate.getTime() - new Date(stringDate).getTime()
  var sec = diffMs / 1000
  if (sec >= 0 && sec <= 120) {
    return 'Just Now'
  }
  if (sec >= 30 && sec <= 60) {
    return parseInt(sec) + ' second' + (parseInt(sec) > 1 ? 's' : '') + ' ago'
  }
  var min = sec / 60
  if (min < 60) {
    return parseInt(min) + ' minute' + (parseInt(min) > 1 ? 's' : '') + ' ago'
  }
  var h = min / 60
  if (h < 24) {
    let val = moment(takvalue, 'hh:mm').format('hh:mm A')
    return 'Today' + " " +  val
  }
  return moment(String(new Date(stringDate))).calendar(null, {
    sameDay: '[Today] hh:mm a',
    lastDay: '[Yest] hh:mm a',
    nextWeek: 'ddd, DD MMM, hh:mm a',
    lastWeek: 'ddd, hh:mm a',
    sameElse: 'DD MMM ddd, hh:mm a'
    // sameElse: 'ddd, DD MMM, hh:mm a'
  })
  // if (h > 48) {
  //   return moment(takvalue, 'ddd DD-MMM-YYYY, hh:mm a').format('ddd DD-MMM-YY, hh:mm a')
  // }
})
Vue.filter('inttotime', function (time) {
  var minutes = parseInt(time % 60, 10)
  var hours = parseInt(time / 60 % 24, 10)
  var session = ''
  minutes = minutes + ''
  if (hours < 12) {
    session = 'AM'
  } else {
    session = 'PM'
  }
  if (hours === 0) {
    hours = 12
  }
  if (hours > 12) {
    hours = hours - 12
  }
  if (minutes.length === 1) {
    minutes = 0 + minutes
  }
  return hours + ':' + minutes + ' ' + session
})

Vue.filter('dateTimeWithName', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  return moment(dateTime).format('lll')
})
Vue.filter('dateTimeFilter', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  return moment(dateTime).format('DD-MM-YYYY HH:mm A')
})

Vue.filter('CustomDateTimeFilter', function (dateTime, formate) {
  if (dateTime === '') {
    return ''
  }
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  return moment(time).format(formate)
})
Vue.filter('dateFilter', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  return moment(time).format(store.getters.dateTimeFormate.split(' ')[0])
})

Vue.filter('onlyTimeFilter', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  return moment(time).format(formate)
})

Vue.filter('YearAndMonthDiffFilter', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var utcTime = new Date(dateTime).toUTCString()
  var dbDate = new Date(utcTime)
  var today = new Date()
  var m = moment(today)
  var years = m.diff(dbDate, 'years')
  m.add(-years, 'years')
  var months = m.diff(dbDate, 'months')
  m.add(-months, 'months')
  var age = ''
  if (months === 0) {
    age += years + ' y'
  } else if (years === 0) {
    age += months + ' m'
  } else {
    age += years + ' y ' + months + ' m'
  }
  return age
})
Vue.filter('getFileSize', function (bytes) {
  // console.log(type)
  // var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  // if (bytes === 0) return '0 Byte'
  // var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  // return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
  // console.log('bytes', bytes)
  if (bytes === 0) return '0 Bytes'
  var decimals = 2
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})
Vue.filter('formatDateTimeLead', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var time = new Date(dateTime)
  return moment(time).format('MMM DD, YYYY hh:mm a')
})
Vue.filter('getFileIcon', function (type) {
  var fileArray = type.split('.')
  if (fileArray.length > 1) {
    type = fileArray[fileArray.length - 1]
  }
  type = type.toLowerCase()
  if (type === 'pdf') {
    return 'far fa-file-pdf'
  } else if (type === 'doc' || type === 'docx' || type === 'odt') {
    return 'far fa-file-word'
  } else if (type === 'xls' || type === 'xlsx' || type === 'ods') {
    return 'far fa-file-excel'
  } else if (type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'gif') {
    return 'fa-solid fa-image'
  } else if (type === 'zip' || type === 'rar') {
    return 'far fa-file-archive'
  } else if (type === 'mp3' || type === 'wav' || type === 'aac') {
    return 'fas fa-music'
  } else {
    return 'far fa-file-alt'
  }
})
Vue.filter('FileManagerFileIcon', function (type) {
  var fileArray = type.split('.')
  if (fileArray.length > 1) {
    type = fileArray[fileArray.length - 1]
  }
  type = type.toLowerCase()
  if (type === 'pdf' || type === 'application/pdf') {
    return 'fas fa-file-pdf'
  } else if (type === 'folder') {
    return 'fas fa-folder'
  } else if (type === 'text/plain' || type === 'text/plain; charset=utf-8') {
    return 'fas fa-file-text'
  } else if (type === 'doc' || type === 'docx' || type === 'odt') {
    return 'fas fa-file-word'
  } else if (type === 'xls' || type === 'xlsx' || type === 'ods') {
    return 'fas fa-file-excel'
  } else if (type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'gif' || type === 'svg' || type === 'image/jpeg' || type === 'image/svg+xml' || type === 'image/webp' || type === 'text/xml; charset=utf-8' || type === 'image/png') {
    return 'fas fa-image'
  } else if (type === 'zip' || type === 'rar' ||type === 'application/x-zip-compressed') {
    return 'fas fa-zip'
  } else if (type === 'mp3' || type === 'wav' ||  type === 'audio/mpeg') {
    return 'fas fa-music'
  } else if (type === 'mov' || type === 'mvi' || type === 'mp4' || type === 'mkv' || type === 'mpeg' || type === 'avi' || type === 'mpeg4' || type === 'video/x-matroska' || type === 'video/mp4'  || type === 'video/webm') {
    return 'fas fa-file-video'
  } else {
    return 'fas fa-folder'
  }
})
Vue.filter('amountFormater', function (amount) {
  // return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `$${amount.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
})
Vue.filter('amountFormaterWithToFix', function (amount) {
  let value = parseFloat(amount).toFixed(2)
  // return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `$${value.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
})
Vue.filter('decimalFilter', function (value, prec) {
  return parseFloat(value).toFixed(prec)
})

Vue.filter('amountOnlyNumber', function (amount) {
  // return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `$${amount.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
  // return amount
})

Vue.filter('timeAgoFilter', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  let todayDate = new Date().setHours(0, 0, 0)
  let requestDt = dateTime
  requestDt = new Date(requestDt).toUTCString()
  requestDt = new Date(requestDt).setHours(0, 0, 0)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  if (moment(todayDate).diff(requestDt, 'days') === 0) {
    let utcTime = new Date(dateTime).toUTCString()
    let time = new Date(utcTime)
    return 'Today ' + moment(time).format(formate)
  }
  if (moment(todayDate).diff(requestDt, 'days') === 1) {
    let utcTime = new Date(dateTime).toUTCString()
    let time = new Date(utcTime)
    return 'Yesterday ' + moment(time).format(formate)
  }
  let utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  return moment(time).format(store.getters.dateTimeFormate)
  // var someDate = moment(time).fromNow()
})
Vue.filter('timeAgoFilterWithDay', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  let todayDate = new Date().setHours(0, 0, 0)
  let requestDt = dateTime
  // requestDt = new Date(requestDt).toUTCString()
  requestDt = new Date(requestDt).setHours(0, 0, 0)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  if (moment(todayDate).format('YYYY/MM/DD') === moment(requestDt).format('YYYY/MM/DD')) {
    let utcTime = new Date(dateTime).toUTCString()
    let time = new Date(utcTime)
    return 'Today ' + moment(time).format(formate) + ' ' + moment(dateTime).format('(ddd)')
  }
  if (moment(todayDate).diff(requestDt, 'days') === 1) {
    let utcTime = new Date(dateTime).toUTCString()
    let time = new Date(utcTime)
    return 'Yesterday ' + moment(time).format(formate) + ' ' + moment(dateTime).format('(ddd)')
  }
  let utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  return moment(time).format(store.getters.dateTimeFormate) + ' ' + moment(dateTime).format('(ddd)')
  // var someDate = moment(time).fromNow()
})
Vue.filter('OnlyDateTimeAgoFilterWithDay', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  let todayDate = new Date().setHours(0, 0, 0)
  let requestDt = dateTime
  // requestDt = new Date(requestDt).toUTCString()
  requestDt = new Date(requestDt).setHours(0, 0, 0)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  if (moment(todayDate).format('YYYY/MM/DD') === moment(requestDt).format('YYYY/MM/DD')) {
    return 'Today' + ' ' + moment(dateTime).format('(ddd)')
  }
  if (moment(todayDate).diff(requestDt, 'days') === 1) {
    return 'Yesterday' + ' ' + moment(dateTime).format('(ddd)')
  }
  let utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  return moment(time).format(formate) + ' ' + moment(dateTime).format('(ddd)')
  // var someDate = moment(time).fromNow()
})
Vue.filter('OnlyDateMonthFilterWithoutZero', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  let formatTime = moment(time).format('MM/DD')
  formatTime = formatTime.toLowerCase()
  if (formatTime.substring(0, 1) === '0') {
    formatTime = formatTime.substring(1)
  }
  let formatTimeArray = formatTime.split('/')
  if (formatTimeArray[1].substring(0, 1) === '0') {
    formatTime = formatTimeArray[0] + '/' + formatTimeArray[1].substring(1)
  }
  return formatTime
})
Vue.filter('OnlyDateMonthFilterWithoutZeroFromLocal', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var time = new Date(dateTime)
  let formatTime = moment(time).format('MM/DD')
  formatTime = formatTime.toLowerCase()
  if (formatTime.substring(0, 1) === '0') {
    formatTime = formatTime.substring(1)
  }
  let formatTimeArray = formatTime.split('/')
  if (formatTimeArray[1].substring(0, 1) === '0') {
    formatTime = formatTimeArray[0] + '/' + formatTimeArray[1].substring(1)
  }
  return formatTime
})
Vue.filter('OnlyTimeFilterWithoutZeroFromLocal', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var time = new Date(dateTime)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  let formatTime = moment(time).format(formate)
  formatTime = formatTime.toLowerCase()
  if (formatTime.indexOf('am') > 0 || formatTime.indexOf('pm') > 0) {
    if (formatTime.substring(0, 1) === '0') {
      formatTime = formatTime.substring(1)
    }
    let formatTimeArray = formatTime.split(':')
    if (formatTimeArray[1].substring(0, 2) === '00') {
      formatTime = formatTimeArray[0] + ' ' + formatTimeArray[1].split(' ')[1]
    } else {
      formatTime = formatTimeArray[0] + ':' + formatTimeArray[1]
    }
    // else if (formatTimeArray[1].substring(0, 1) === '0') {
    //   formatTime = formatTimeArray[0] + ':' + formatTimeArray[1].substring(1)
    // }
  } else {
    if (formatTime.substring(0, 1) === '0') {
      formatTime = formatTime.substring(1)
    }
  }
  return formatTime
})
Vue.filter('OnlyTimeFilterWithoutZero', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  var formate = store.getters.dateTimeFormate
  formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
  let formatTime = moment(time).format(formate)
  formatTime = formatTime.toLowerCase()
  if (formatTime.indexOf('am') > 0 || formatTime.indexOf('pm') > 0) {
    if (formatTime.substring(0, 1) === '0') {
      formatTime = formatTime.substring(1)
    }
    let formatTimeArray = formatTime.split(':')
    if (formatTimeArray[1].substring(0, 2) === '00') {
      formatTime = formatTimeArray[0] + ' ' + formatTimeArray[1].split(' ')[1]
    } else {
      formatTime = formatTimeArray[0] + ':' + formatTimeArray[1]
    }
  } else {
    if (formatTime.substring(0, 1) === '0') {
      formatTime = formatTime.substring(1)
    }
  }
  return formatTime
})
Vue.filter('SubStringFilter', function (text, start, end) {
  if (text === null) {
    return ''
  }
  if (end > 1) {
    return text.substring(start, end).toUpperCase()
  } else {
    return text.substring(start, end)
  }
})
Vue.filter('capitalizeFirstLetter', function (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
})
// Vue.filter('getDay', function (day) {
//   return moment(day).format('ddd')
// })
Vue.filter('getDay', function (day) {
  return moment(day).format('d')
})
Vue.filter('getShortDate', function (dateTime, isshowday) {
  var daySuffix = ''
  if (isshowday) {
    let todayDate = new Date().setHours(0, 0, 0)
    let requestDt = dateTime
    // requestDt = new Date(requestDt).toUTCString()
    requestDt = new Date(requestDt).setHours(0, 0, 0)
    // var formate = store.getters.dateTimeFormate
    // formate = formate.replace(store.getters.dateTimeFormate.split(' ')[0] + ' ', '')
    if (moment(todayDate).format('YYYY/MM/DD') === moment(requestDt).format('YYYY/MM/DD')) {
      daySuffix = ' (today) '
    }
  }
  return moment(dateTime).format('MM/DD ddd') + daySuffix
})

Vue.filter('getDateOnly', function (day) {
  if (moment(day).format('DD') < 9) {
    return moment(day).format('D')
  } else {
    return moment(day).format('DD')
  }
})

Vue.filter('dateFilterWithDateAndMonth', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var utcTime = new Date(dateTime).toUTCString()
  var time = new Date(utcTime)
  return moment(time).format('MM/DD')
})

Vue.filter('timeStringFilter', function (time) {
  if (time === undefined) {
    return ''
  }
  let timeArry = time.split(':')

  var minutes = parseInt(timeArry[1])
  var hours = parseInt(timeArry[0])
  var session = ''
  minutes = minutes + ''
  if (hours < 12) {
    session = 'AM'
  } else {
    session = 'PM'
  }
  if (hours === 0) hours = 12
  if (hours > 12) {
    hours = hours - 12
  }
  if (minutes.length === 1) minutes = 0 + minutes
  return hours + ':' + minutes + ' ' + session
})
Vue.filter('distanceUnit', function (distance) {
  if (distance.trim() === '') {
    return distance
  }
  if (store.getters.distanceUnit === 'mile') {
    return distance
  }
  var d = distance.split(' ')
  if (d[1].trim() === 'ft') {
    return distance
  }
  if (store.getters.distanceUnit === 'km') {
    d[0] = d[0] * 1.60934
    d[1] = 'km'
    distance = d[0].toFixed(2) + ' ' + d[1]
    return distance
  }
})
Vue.filter('SubStringCheckAppendFilter', function (str) {
  let countyName = str.toLowerCase()
  if (countyName.indexOf('county') >= 0) {
    return str
  } else {
    countyName = str + ' county'
  }
  return countyName
})
Vue.filter('dateTimeFilter', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var time = new Date(dateTime)
  return moment(time).format('MM/DD/YY hh:mm a')
})
Vue.filter('formatDataeTime', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  var time = new Date(dateTime)
  return moment(time).format('DD-MM-YY hh:mm')
})
Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(new Date(value))).calendar(null, {
      sameDay: '[Today]  hh:mm a',
      nextDay: '[Tomorrow]  hh:mm a',
      nextWeek: 'dddd hh:mm a',
      lastDay: '[Yesterday]  hh:mm a',
      lastWeek: store.getters.dateTimeFormate,
      sameElse: store.getters.dateTimeFormate
    })
    //  moment().calendar()
  }
})
Vue.filter('allhours', function (value) {
  if (value) {
    return moment(String(value)).format('HH:mm')
  }
})
Vue.filter('timeTitleHistory', function (value) {
  if (value) {
    return moment(String(new Date(value))).calendar(null, {
      sameDay: '[Today :] MM/DD/YY',
      nextWeek: 'dddd hh:mm a',
      lastDay: '[Yesterday :] MM/DD/YY',
      lastWeek: 'ddd, DD/MM/YY',
      sameElse: 'ddd, DD/MM/YY'
    })
    //  moment().calendar()
  }
})
Vue.filter('leaveTimeFilter', function (value) {
  if (value) {
    return moment(String(new Date(value))).calendar(null, {
      sameDay: '[Today] DD/MM/YY hh:mm A',
      nextDay: '[Tomorrow] DD/MM/YY hh:mm A',
      lastDay: '[Yesterday] DD/MM/YY hh:mm A',
      nextWeek: 'ddd, DD/MM/YY hh:mm A',
      lastWeek: 'ddd, DD/MM/YY hh:mm A',
      sameElse: 'ddd, DD/MM/YY hh:mm A'
    })
    //  moment().calendar()
  }
})
Vue.filter('leaveTimeFilterForActivity', function (value) {
  if (value) {
    return moment(String(new Date(value))).calendar(null, {
      sameDay: 'hh:mm A',
      nextDay: 'hh:mm A',
      lastDay: 'hh:mm A',
      nextWeek: 'hh:mm A',
      lastWeek: 'hh:mm A',
      sameElse: 'hh:mm A'
    })
    //  moment().calendar()
  }
})
Vue.filter('getDate', function (value) {
  if (value) {
    return moment(String(value)).format(store.getters.dateTimeFormate)
  }
})
Vue.filter('getDay', function (value) {
  if (value) {
    return moment(String(value)).format('ddd')
  }
})
Vue.filter('getTime', function (value) {
  if (value) {
    return moment(String(value)).format('hh:mm a')
  }
})
Vue.filter('getTimeCaps', function (value) {
  if (value) {
    return moment(String(value)).format('hh:mm A')
  }
})
Vue.filter('amountFormaterForTax', function (amount) {
  // return '$' + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return `$${amount.toLocaleString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`
})
Vue.filter('ConvertMinutesInHoursAndMinutes', function (totalMinutes) {
  var hours = 0
  var minutes = 0
  var displayText = ''
  minutes = totalMinutes % 60
  if (totalMinutes >= 60) {
    hours = Math.floor(totalMinutes / 60)
    minutes = totalMinutes % 60
    let hoursText = (hours > 0) ? (hours < 10) ? '' + hours + ' hr' : hours + ' hr' : ''
    let minutesText = (minutes > 0) ? (minutes < 10) ? ' 0' + minutes + ' min' : minutes + ' min' : ''
    if (hoursText !== '' || minutesText !== '') {
      displayText += '' + hoursText
      displayText += (minutesText !== '' && hoursText) ? ', ' : ''
      displayText += minutesText
    }
  } else {
    displayText = minutes + ' min'
  }
  return displayText
})

Vue.filter('dateStringFilter', function (value) {
  if (value === '') {
    return ''
  }
  // let displayText = ''
  // let y = moment().diff(moment(value), 'years')
  // if (y > 0) {
  //   displayText += (moment(String(value)).format('MMM DD, YYYY').toString())
  // } else {
  //   displayText += (moment(String(value)).format('MMM DD, YYYY').toString())
  // }
  return moment(String(value)).format('MMM DD, YYYY').toString()
})

Vue.filter('formatForDatePicker', function (value) {
  if (value) {
    return moment(value).format('YYYY-MM-DD').toString()
  } else {
    return moment().format('YYYY-MM-DD').toString()
  }
})
Vue.filter('formatForImpDate', function (value) {
  if (value) {
    return moment(value).format('YY-MM-DD').toString()
  } else {
    return moment().format('YY-MM-DD').toString()
  }
})

Vue.filter('time12HourFormat', function (value) {
  if (value) {
    return moment(String(value), 'hh:mm').format('hh:mm a')
  }
})

Vue.filter('TimeIntoString', function (value) {
  if (value === '') return ''
  value = Math.abs(value)
  let x = ''
  let hours = Math.abs(Math.floor(value / 60))
  if (hours > 0) {
    x += hours
    x += ' hr'
  } else {
    x = ''
  }
  let mins = Math.abs(Math.round(value % 60))
  if (hours > 0 && mins > 0) {
    x += ', '
  }
  if (mins > 0) {
    x += mins
    x += ' min'
  }
  if (hours === 0 && mins === 0) {
    return '0 min'
  }
  return x
})

Vue.filter('time12HourFormat', function (value) {
  if (value) {
    return moment(String(value), 'hh:mm').format('hh:mm a')
  }
})

Vue.filter('subtractOneDay', function (value) {
  if (value) {
    return moment(String(value)).subtract(1, 'days').format('YYYY-MM-DD').toString()
  } else {
    return moment().subtract(1, 'days').format('YYYY-MM-DD').toString()
  }
})

Vue.filter('addOneDay', function (value) {
  if (value) {
    return moment(String(value)).add(1, 'days').format('YYYY-MM-DD').toString()
  } else {
    return moment().add(1, 'days').format('YYYY-MM-DD').toString()
  }
})

Vue.filter('getFullDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD-MM-YYYY').toString()
  }
})

Vue.filter('getIconForPhone', function (value) {
  if (value) {
    if (value.toLowerCase() === 'home') {
      return 'fas fa-home'
    } else if (value.toLowerCase() === 'personal') {
      return 'fas fa-user'
    } else if (value.toLowerCase() === 'office') {
      return 'fas fa-industry'
    }
  }
})

Vue.filter('GetHoursFromTotalTime', function (value) {
  if (value === '') return ''
  value = Math.abs(value)
  let x = ''
  let hours = Math.abs(Math.floor(value / 60))
  if (hours > 0) {
    x += hours < 10 ? '0' : ''
    x += hours
  } else {
    x += '00'
  }
  return x
})

Vue.filter('GetMinutesFromTotalTime', function (value) {
  if (value === '') return ''
  value = Math.abs(value)
  let x = ''
  let mins = Math.abs(Math.round(value % 60))
  if (mins > 0) {
    x += mins < 10 ? '0' : ''
    x += mins
  } else {
    x += '00'
  }
  return x
})

Vue.filter('GetHoursAndMinutesFromValue', function (value) {
  if (value === '') return ''
  value = Math.abs(value)
  let x = ''
  let hours = Math.abs(Math.floor(value / 60))
  if (hours > 0) {
    x += hours < 10 ? '0' : ''
    x += hours + ' hr'
  }
  let mins = Math.abs(Math.round(value % 60))
  if (mins > 0) {
    if (hours > 0) {
      x += ', '
    }
    x += mins < 10 ? '0' : ''
    x += mins + ' min'
  }
  return x
})

Vue.filter('GetHoursAndMinutesFromValueInHMFormat', function (value) {
  if (value === '') return ''
  value = Math.abs(value)
  let x = ''
  let hours = Math.abs(Math.floor(value / 60))
  if (hours > 0) {
    x += hours < 10 ? '0' : ''
    x += hours + ' h, '
  }
  let mins = Math.abs(Math.round(value % 60))
  if (mins > 0) {
    x += mins < 10 ? '0' : ''
    x += mins + ' m'
  }
  return x
})

Vue.filter('GetDayMonthDate', function (value) {
  if (value === '') return ''
  return moment(value).format('ddd,MMM DD')
})

Vue.filter('DMMYYYYddd', function (value) {
  if (value === '') return ''
  return moment(value).format('DD/MM/YY (ddd)')
})

Vue.filter('filterToolbarTitle', function (value) {
  if (value === '' || value === undefined) return ''
  let str = ''
  if (value.length > 100) {
    str += value.slice(0, 99) + '...'
  } else {
    str = value
  }
  return str
})

Vue.filter('GetHoursMinuteAndSeconds', function (totalSeconds) {
  return `${Math.round((totalSeconds / (3600))) >= 1 ? (totalSeconds / 3600) + ' hour, ' : ''} ${(totalSeconds / 60) >= 1 ? Math.round((totalSeconds / (60))) + ' minutes, ' : ''} ${(totalSeconds / 60) >= 1 ? (totalSeconds % 60) : totalSeconds} seconds`
})

Vue.filter('DatepickerDateFormat', function (value) {
  if (value === '') return ''
  return moment(value).format('MMM, YYYY')
})
Vue.filter('YearPickerMonthTitle', function (value) {
  if (value === '') return ''
  return moment(value).format('MMM, YYYY')
})
Vue.filter('DatepickerMonthFormat', function (value) {
  if (value === '') return ''
  return moment(value).format('YYYY')
})

Vue.filter('DatePickerDateHeader', function (value) {
  if (value === '') return ''
  return moment(value).format('dddd MMM DD, YYYY')
})
Vue.filter('DatePickerTimeHeader', function (value) {
  if (value === '') return ''
  console.log('Value coming', value)
  return moment(value, 'hh:mm').format('hh:mm A')
  // let x = value.split(':')
  // return `${parseInt(x[0]) > 12 ? parseInt(x[0]) - 12 : parseInt(x[0])}:${x[1]} ${parseInt(x[0]) > 12 ? 'PM' : 'AM'}`
})
Vue.filter('HowManyDaysRemaining', function (value) {
  if (value) {
    return moment().to(moment(value))
  }
})
Vue.filter('onlyDate', function (value) {
  if (value === '') return ''
  return moment(value).format('MM/DD/YYYY')
})


Vue.filter('getStatusName', function (statusTypeId) {
  var wpStatusTypeList = store.getters.wpStatusTypeList
  for (let index = 0; index < wpStatusTypeList.length; index++) {
    if (statusTypeId === wpStatusTypeList[index].id) {
      return wpStatusTypeList[index].displayText
    }
  }
})

Vue.filter('dateFilters', function (dateTime) {
  if (dateTime === '') {
    return ''
  }
  return moment(dateTime).format('lll')
})