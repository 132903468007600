<template>
  <svg :width="width" :height="height" :fill="color" viewBox="0 0 28 28"  xmlns="http://www.w3.org/2000/svg">
<path d="M9.75 6.24774C9.75 3.90053 11.6528 1.99774 14 1.99774C16.3472 1.99774 18.25 3.90053 18.25 6.24774C18.25 8.33904 16.7395 10.0775 14.75 10.4318V13.5H19.25C20.4926 13.5 21.5 14.5074 21.5 15.75V17.566C23.4895 17.9202 25 19.6587 25 21.75C25 24.0972 23.0972 26 20.75 26C18.4028 26 16.5 24.0972 16.5 21.75C16.5 19.6587 18.0105 17.9202 20 17.566V15.75C20 15.3358 19.6642 15 19.25 15H8.75C8.33579 15 8 15.3358 8 15.75V17.566C9.98951 17.9202 11.5 19.6587 11.5 21.75C11.5 24.0972 9.59721 26 7.25 26C4.90279 26 3 24.0972 3 21.75C3 19.6587 4.51049 17.9202 6.5 17.566V15.75C6.5 14.5074 7.50736 13.5 8.75 13.5H13.25V10.4318C11.2605 10.0775 9.75 8.33903 9.75 6.24774Z" :fill="color"/>
</svg>
</template>
<script>
export default {
  name: 'sheduleSvg', // vue component name
  props: {
    color: {
      type: String,
      default: 'black'
    },
    width: {
      type: String,
      default: '40px'
    },
    height: {
      type: String,
      default: '40px'
    }
  },
  data () {
    return {
    }
  }
}
</script>
